<template>
    <section class="comment">
        <div class="comment__custom-container">
            <form class="comment__form" @submit.prevent="postComment">
                <h2>Share Your Thoughts</h2>
                <div class="comment__area">
                    <textarea name="comment" rows="8" cols="80" placeholder="Write here" v-model="comment" required></textarea>
                </div>
                <div class="comment__input">
                    <input type="text" name="name" placeholder="Name" v-model="name" required>
                    <input type="text" name="surname" placeholder="Surname" v-model="surname">
                </div>
                <input class="comment__button" type="submit" name="submit" value="Post">
            </form>
        </div>
        <div class="comment__box">
            <div class="comment__txt" v-for="(item, index) in comments" :key="index">
                <p>{{ item.comment_content }}</p>
                <p>{{ item.comment_author }}</p>
            </div>
            <!-- <div class="comment__btn comment__animated">
                <button>see more</button>
            </div> -->
        </div>
        <div class="comment__logo">
            <img src="@/assets/Group-35.svg" alt="logo">
        </div>
    </section>
</template>

<script>
import axios from 'axios'
export default{
    data(){
        return{
            comments: Array,
            name: '',
            surname: '',
            comment: '',
            type: 'xmas-comment'
        }
    },
    methods:{
        postComment(){
            var bodyFormData = new FormData();
            bodyFormData.set('comment_author', this.name+' '+this.surname);
            bodyFormData.set('comment_type', this.type);
            bodyFormData.set('comment_content', this.comment);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            axios.post( process.env.VUE_APP_API + 'wp-json/better-api/v1/comment/', bodyFormData, config).then((response) => {
                this.getComments()
                this.name = ''
                this.surname = ''
                this.comment = ''
            }).catch((err) => {
                console.log("Error")
            })
        },
        getComments(){
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            axios.get( process.env.VUE_APP_API + 'wp-json/better-api/v1/comment/', config).then((response) => {
                if(response.statusText == 'OK'){
                    this.comments = response.data
                } else {
                    console.log('Error')
                }
            }).catch((err) => {
                console.log('Error');
            })
        }
    },
    mounted(){
        this.getComments();
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.comment {
    &__custom-container {
        margin: 40px 0 8px 0 ;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    &__form {
        text-align: center;
        max-width: 700px;
        @include tablet-s {
            margin: 0 40px;
        }
        @include mobile-xs {
            margin: 0 20px;
        }
        h2 {
            text-align: center;
            margin-bottom: 40px;
            text-align: center;
            font-family: 'Playfair Display', serif;
            color: $gold;
            font-size: 2rem;
        }
    }
    &__input {
        input {
            font-family: 'Montserrat', sans-serif;
            padding: 10px 0;
            width: 50%;
            border: none;
            border-top: 1px solid $lightgrey;
            border-bottom: 1px solid $lightgrey;
            outline: none;
            font-size: 1.2rem;
            @include tablet-m {
                padding: 10px 0;
                width: 100%;
            }
            @include mobile-xs {
                width: 100%;
            }
        }
        @include tablet-m {
            input:nth-of-type(2) {
                border-top: none;
            }
        }
        input::placeholder {
            color: $grey;
        }
    }
    &__area {
        textarea {
            font-family: 'Montserrat', sans-serif;
            padding-top: 10px;
            width: 100%;
            border: none;
            resize: none;
            outline: none;
            border-top: 1px solid $lightgrey;
            font-size: 1.2rem;
            @include tablet-m {
                padding-top: 10px;
            }
        }
        textarea::placeholder {
            color: $grey;
        }
    }
    &__button {
        font-family: 'Montserrat', sans-serif;
        border: none;
        background-color: #FFF;
        color: $granat;
        outline: none;
        text-decoration: underline;
        text-decoration-color: $lightgrey;
        cursor: pointer;
        padding: 10px 0;
        font-size: 1.2rem;
    }
    &__box {
        box-shadow: inset 0px 2px 4px -1px rgba(0,0,0,0.3);
        background-color: $ligtergrey;
    }
    &__txt {
        margin: 0 auto;
        max-width: 1200px;
        padding: 70px 0 30px;
        text-align: center;
        @include desktop-l {
            padding: 40px 60px 0;
        }

        @include mobile-xs {
            padding: 40px 20px 0;
        }
        p:nth-of-type(1) {
            border-top: 1px solid $lightgrey;
            font-family: 'Playfair Display', serif;
            color: $gold;
            font-size: 2rem;
            @include tablet-m {
            padding-top: 12px;
            }
            @include mobile-xs {
            padding-top: 8px;
            }
        }
        p:nth-of-type(2) {
            padding: 14px 0 20px 0;
            font-family: 'Montserrat', sans-serif;
            color: $granat;
            font-size: 1rem;
            text-transform: uppercase;
        }
    }
    &__btn {
        padding: 30px 0;
        display: flex;
        justify-content: center;
        button {
            font-weight: bolder;
            outline: none;
            cursor: pointer;
            box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3);
            font-family: 'Montserrat', sans-serif;
            background-color: $gold;
            border: none;
            line-height: 50%;
            height: 50px;
            width: 270px;
            text-transform: uppercase;
            color: #FFF;
            padding: 20px;
            transition: all .2s;
            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 6px 6px rgba(0,0,0, .2);

                &::after {
                    transform: scaleX(1.4) scaleY(1.6);
                    opacity: 0;
                }
            &:active {
                    transform: translateY(-1px);
                    box-shadow: 0 6px 6px rgba(0,0,0, .2);
                }
            }
            @include mobile-xs{
                width: 180px;
            }
        }
    }
    &__logo {
        display: flex;
        justify-content: center;
        margin: 92px 0;
        @include tablet-m {
            margin: 62px 40px;
        }
        img {
            width: 760px;
        }
    }
}
</style>
