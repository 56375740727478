<template>
    <div>
        <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item" href="/" @click.prevent="$router.push('/')">
                    <img src="@/assets/logo.svg" width="auto" height="52">
                </a>
                <a
                    role="button"
                    aria-label="menu"
                    aria-expanded="false"
                    class="navbar-burger burger"
                    :class="{'is-active': burger}"
                    @click="burger = !burger"
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div
                class="navbar-menu"
                :class="{'is-active': burger}"
            >
                <div class="navbar-start">
                    <div
                        class="navbar-item"
                        @click="activeSubmenu = (activeSubmenu == menuKey) ? 0 : menuKey"
                        :class="{'has-dropdown is-hoverable':menuItem.children, 'is-active':activeSubmenu == menuKey}"
                        v-for="(menuItem, menuKey) in mainMenu"
                    >
                        <a
                            :class="{'menu-item':!menuItem.children, 'navbar-link':menuItem.children}"
                            :href="'/'+menuLocale+menuItem.url"
                            @click.prevent="menuItem.children ? '' : navigateTo('/'+menuItem.url)"
                            v-html="menuItem.title"
                        ></a>
                        <div
                            class="navbar-dropdown"
                            v-if="menuItem.children"
                        >
                            <a
                                v-for="subMenuItem in menuItem.children"
                                class="navbar-item"
                                :href="'/'+menuLocale+subMenuItem.url"
                                @click.prevent="navigateTo('/'+subMenuItem.url)"
                                v-html="subMenuItem.title"
                            ></a>
                        </div>
                    </div>
                </div>
                <div
                    class="navbar-end"
                    v-if="languages.length > 1"
                >
                    <div
                        class="navbar-item has-dropdown is-hoverable"
                        :class="{'is-active': menuLang}"
                    >
                        <a
                            class="navbar-link"
                            v-for="lang in languages"
                            @click="menuLang = !menuLang"
                            :href="langHomeUrl(lang.home_url)"
                            v-if="lang.lang == locale"
                        >
                            {{ lang.name }}
                        </a>
                        <div class="navbar-dropdown">
                            <a
                                class="navbar-item"
                                v-for="lang in languages"
                                :href="langHomeUrl(lang.home_url)"
                                v-if="lang.lang != locale"
                            >
                                {{ lang.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            burger: false,
            menuLang: false,
            activeSubmenu: 0
        }
    },
    created() {

    },
    methods: {
        langHomeUrl(home_url) {
            return this.publicPath + home_url
        },
        navigateTo(url){
            const publicPath = '/' + this.$store.getters.getPublicPath
            const locale = this.$store.getters.getLocale
            if(url != '#'){
             this.$router.push(url)
             this.burger = false
            }
        }
    },
    computed: {
        languages(){
            return this.$store.getters.getLanguages
        },
        options(){
            return this.$store.getters.getOptions
        },
        locale(){
            return this.$store.getters.getLocale
        },
        menuLocale(){
            return (this.locale != this.$store.getters.getOptions.default_language) ? this.locale+'/' : ''
        },
        publicPath(){
            return this.$store.getters.getPublicPath
        },
        mainMenu(){
            return this.$store.getters.getMainMenu
        }
    },
}
</script>

<style scoped lang="sass">
</style>
