<template>
    <div v-if="isLogged">
        <div class="background" :class="{ 'background__switch': backgroundSwitch }"></div>
        <div class="main-container" id="main">
            <flexible-content/>
        </div>
    </div>
</template>

<script>
import FlexibleContent from '@/components/FlexibleContent.vue'
export default {
    data(){
        return{
            backgroundSwitch: false
        }
    },
    components: {
        'flexible-content': FlexibleContent
    },
    computed: {
        page() {
            return this.$store.getters.getPageByPath(this.$route.path)
        },
        isLogged(){
            if(sessionStorage.getItem('tkp-logged-in') == 1) {
				return true;
			} else return false;
        }
    },
    methods: {
        updateScroll() {
            if (document.getElementById("main").scrollTop > 100) {
                this.backgroundSwitch = true
            }
            if (document.getElementById("main").scrollTop < 100) {
                this.backgroundSwitch = false
            }
        }
    },
    mounted() {
        const mainDoc = document.getElementById("main")
        if (mainDoc) {
            mainDoc.addEventListener('scroll', this.updateScroll)
        }
    },
    created(){
        if (sessionStorage.getItem('tkp-logged-in') != 1) {
            this.$router.push('/')
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/framework/variables.sass";

.background{
    background-color: #FFF;
    transition: background-color 1.5s ease;
    width: 100%;
    height: 100vh;
    &__switch{
        background-color: #B79F87;
    }
}

.main-container{
    width: calc(100% - 80px);
    height: calc(100vh - 80px);
    overflow-y: scroll;
    top: 40px;
    left: 40px;
    position: fixed;
    background-color: #FFF;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, .3);
    @include mobile{
        box-shadow: none;
        width: calc(100% - 20px);
        height: calc(100vh - 20px);
        top: 10px;
        left: 10px;
    }
}

.video{
    width: 100%;
    height: calc(100vh - 80px);
    background-size: cover;
    background-position: center;
}

::-webkit-scrollbar {
    width: 5px;
    z-index: 20px;
    display: none;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    z-index: 20px;
}

::-webkit-scrollbar-thumb {
    background: $gold;
    z-index: -99;
}
</style>
