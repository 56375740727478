<template>
    <footer class="footer">        
    </footer>
</template>

<script>
export default {
}
</script>

<style scoped lang="sass">
</style>
