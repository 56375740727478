<template>
    <section>
        <div class="video" :style="{ backgroundImage: 'url(' + data.video + ')' }" @click="open()">
            <img src="@/assets/twologo.svg" class="video__logo">
            <img src="@/assets/play.svg" class="video__play">
            <span>SCROLL DOWN</span>
        </div>
        <div class="vimeo-wrapper" v-if="show">
            <div class="test">

            </div>
            <button type="button" class="video__modal-close" @click="close()"><img src="@/assets/close-white.svg"></button>
            <!-- <vimeo-player ref="player" :video-id="videoID" @ready="onReady"></vimeo-player> -->
            <iframe src="https://player.vimeo.com/video/378524120" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
    </section>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default{
    data(){
        return{
            show: false,
            videoID: '0bb05868f2'
        }
    },
    components: {
        'vimeo-player': vueVimeoPlayer
    },
    methods: {
        open(){
            this.play
            this.show = true
        },
        close(){
            this.pause
            this.show = false
        },
        play() {
            this.$refs.player.play()
        },
        pause() {
            this.$refs.player.pause()
        }
    }
}
</script>

<style lang="scss">
.vimeo-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    overflow: hidden;
    background-color: #000;
}
.vimeo-wrapper iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video{
    cursor: pointer;
    position: relative;
    span{
        color: #fff;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        font-size: 2.4rem;
        animation-duration: 3s;
        animation-name: change;
        animation-iteration-count: infinite;
        @keyframes change{
            0% {
                transform: translateY(-5px) translateX(-50%);
            }
            50% {
                transform: translateY(5px) translateX(-50%);
            }
            100% {
                transform: translateY(-5px) translateX(-50%);
            }
        }
    }
    &__modal-close{
        position: fixed;
        top: 25px;
        right: 20px;
        background-color: transparent;
        border: none;
        padding: 20px;
        cursor: pointer;
        z-index: 999999;
    }
    &__logo{
        max-width: 600px;
        width: 90%;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    &__play{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}

</style>
